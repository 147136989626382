import { fileStatusLabeler, phytozomeDataTypeLabeler } from "./labelers";
// eslint-disable-next-line no-unused-vars
import { base as ldbase } from "../utils/LDFFUtils";
import { deepClone } from "../utils/ObjectUtils";

// - global consts
export const NOT_IN_JAMO_META = "not in metadata";

// -- filter specific const and configs
const FILTER_ORGANISM_CLASS = "ncbi_taxon_class";
const FILTER_ORGANISM_ORDER = "ncbi_taxon_order";
const FILTER_ORGANISM_FAMILY = "ncbi_taxon_family";
const FILTER_ORGANISM_GENUS = "ncbi_taxon_genus";
const FILTER_FILE_TYPE = "file_type";
const FILTER_FILE_STATUS = "file_status";
const FILTER_DATA_TYPE = "data_type";
const FILTER_DATA_GROUP = "data_group";

const FILTER_DUS_ID = "data_utilization_status";

export const KINGDOM_PHYTOZOME = "phytozome";
export const KINGDOM_MYCOCOSM = "mycocosm";
export const KINGDOM_IMG = "img";
export const KINGDOM_PHYCOCOSM = "phycocosm";
export const FUNGALALGAE = [KINGDOM_MYCOCOSM, KINGDOM_PHYCOCOSM];

export const COLUMN_FILE_NAME = {
  id: "file_name",
  label: "File name",
  sortable: true,
  align: "left",
};
export const COLUMN_FILE_TYPE = {
  id: "file_type",
  label: "Data type",
  sortable: true,
  align: "left",
};
export const COLUMN_FILE_SIZE = {
  id: "file_size",
  label: "File size",
  sortable: true,
  align: "right",
};
export const COLUMN_FILE_DATE = {
  id: "file_date",
  label: "Last modified",
  sortable: true,
  align: "right",
};
export const COLUMN_DATA_GROUP = {
  id: "data_group",
  label: "Data group",
  sortable: true,
  align: "left",
};
export const COLUMN_FILE_AVAIL = {
  id: "file_status",
  label: "File availability",
  sortable: true,
  align: "center",
};
export const FILE_BASELINE_COLUMNS = [
  COLUMN_FILE_NAME,
  COLUMN_FILE_TYPE,
  COLUMN_DATA_GROUP,
  COLUMN_FILE_SIZE,
  COLUMN_FILE_DATE,
  COLUMN_FILE_AVAIL,
];

export const GOLD_ECOSYSTEM = "ecosystem";
export const GOLD_ECOSYSTEM_CATEGORY = "ecosystem_category";
export const GOLD_ECOSYSTEM_SUBTYPE = "ecosystem_subtype";
export const GOLD_ECOSYSTEM_TYPE = "ecosystem_type";
export const GOLD_SPECIFIC_ECOSYSTEM = "specific_ecosystem";

const GOLD_FILTERS = [
  {
    filterId: GOLD_ECOSYSTEM,
    filterTitle: "Ecosystem",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: GOLD_ECOSYSTEM_CATEGORY,
    filterTitle: "Ecosystem category",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: GOLD_ECOSYSTEM_TYPE,
    filterTitle: "Ecosystem type",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: GOLD_ECOSYSTEM_SUBTYPE,
    filterTitle: "Ecosystem subtype",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: GOLD_SPECIFIC_ECOSYSTEM,
    filterTitle: "Specific ecosystem",
    sort: true,
    filterOptions: "facets",
  },
];

export const FILTER_DUS_TITLE = "Data usage"; // "Data Usage Restriction" : too long for display
const DUS_IN_FILE_FILTERS = [
  {
    filterId: FILTER_DUS_ID,
    filterTitle: FILTER_DUS_TITLE,
  },
];

export const FILTER_VERSION = "superseded";
const VERSION_FILTERS = [
  {
    filterId: FILTER_VERSION,
    filterTitle: "Version",
  },
];

const TAXONOMY_FILTERS = [
  {
    filterId: FILTER_ORGANISM_CLASS,
    filterTitle: "Class",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: FILTER_ORGANISM_ORDER,
    filterTitle: "Order",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: FILTER_ORGANISM_FAMILY,
    filterTitle: "Family",
    sort: true,
    filterOptions: "facets",
  },
  {
    filterId: FILTER_ORGANISM_GENUS,
    filterTitle: "Genus",
    sort: true,
    filterOptions: "facets",
  },
];

export const FILTER_CATEGORY = "dataset_type";
const CATEGORY_FILTERS = [
  {
    filterId: FILTER_CATEGORY,
    filterTitle: "Dataset type",
    sort: true,
  },
];

const DATATYPE_FILTERS = VERSION_FILTERS.concat(CATEGORY_FILTERS);

// eslint-disable-next-line no-unused-vars
const appConfig = (ldClient) => {
  // const jdp1481vers = ldbase(ldClient, FF);

  const config = {
    phytozome: {
      allowNoParamSearch: true,
      kingdom: "Phytozome",
      dataUrl: "/phytozome_file_list/?api_version=2&d=asc",
      suggestionUrl: "/phytozome_file_list/?api_version=2&a=1&x=5",
      // classicDownload:
      //   "//genome.jgi.doe.gov/portal/pages/dynamicOrganismDownload.jsf?organism=Phytozome",
      portalLinkCurrent: (portalId) =>
        `https://phytozome-next.jgi.doe.gov/info/${portalId}`,
      portalLink: (portalId) =>
        `https://phytozome.jgi.doe.gov/pz/portal.html#!info?alias=${portalId}`,
      fileFilters: [
        { filterId: FILTER_FILE_TYPE, filterTitle: "File type", sort: true },
        {
          filterId: FILTER_FILE_STATUS,
          filterTitle: "File availability",
          labeler: fileStatusLabeler,
        },
        {
          filterId: "meta_type",
          filterTitle: "Data type",
          labeler: phytozomeDataTypeLabeler,
          sort: true,
        },
      ]
        .concat(...DUS_IN_FILE_FILTERS)
        .concat([
          {
            filterId: "phytozome_version",
            filterTitle: "Phytozome version",
            sort: true,
            alphaNumeric: true,
          },
        ]),
      organismFilters: [
        { filterId: "phytozome_project", filterTitle: "Project", sort: true },
        { filterId: "phytozome_policy", filterTitle: "Data use" },
      ].concat(TAXONOMY_FILTERS.slice(0, 3)),
      goldFilters: GOLD_FILTERS,
      fileColumns: FILE_BASELINE_COLUMNS,
      datasetFilters: DATATYPE_FILTERS,
    },
    img: {
      kingdom: "IMG",
      dataUrl: "/img_file_list/?api_version=2&d=asc",
      suggestionUrl: "/img_file_list/?api_version=2&a=1&x=5",
      portalLink: (portalId) =>
        `https://img.jgi.doe.gov/cgi-bin/m/main.cgi?section=TaxonDetail&page=taxonDetail&taxon_oid=${portalId}`,
      fileFilters: [
        { filterId: FILTER_FILE_TYPE, filterTitle: "File type", sort: true },
        {
          filterId: FILTER_FILE_STATUS,
          filterTitle: "File availability",
          labeler: fileStatusLabeler,
        },
        { filterId: FILTER_DATA_TYPE, filterTitle: "Data type" },
        {
          filterId: "data_group",
          filterTitle: "Data Group",
        },
      ].concat(...DUS_IN_FILE_FILTERS),
      fileColumns: deepClone(FILE_BASELINE_COLUMNS), // so can be spliced w/o affecting other defs
      goldFilters: GOLD_FILTERS,
      requiredParams: ["its_ap_id", "its_sp_id"],
      datasetFilters: DATATYPE_FILTERS,
    },
    mycocosm: {
      kingdom: "Mycocosm/Phycocosm",
      dataUrl: "/mycocosm_file_list/?api_version=2&d=asc",
      suggestionUrl: "/mycocosm_file_list/?api_version=2&a=1&x=5",
      portalLink: (portalId) =>
        `https://mycocosm.jgi.doe.gov/${portalId}/${portalId}.home.html`,
      fileFilters: [
        { filterId: FILTER_FILE_TYPE, filterTitle: "File type", sort: true },
        {
          filterId: FILTER_FILE_STATUS,
          filterTitle: "File availability",
          labeler: fileStatusLabeler,
        },
        { filterId: FILTER_DATA_TYPE, filterTitle: "Data type", sort: true },
      ].concat(...DUS_IN_FILE_FILTERS),
      fileColumns: FILE_BASELINE_COLUMNS,
      goldFilters: GOLD_FILTERS,
      requiredParams: ["org_name", "organism", "group"],
      datasetFilters: DATATYPE_FILTERS,
    },
    phycocosm: {
      kingdom: "Mycocosm/Phycocosm",
      dataUrl: "/phycocosm_file_list/?api_version=2&d=asc",
      suggestionUrl: "/phycocosm_file_list/?api_version=2&a=1&x=5",
      portalLink: (portalId) =>
        `https://mycocosm.jgi.doe.gov/${portalId}/${portalId}.home.html`,
      fileFilters: [
        { filterId: FILTER_FILE_TYPE, filterTitle: "File type", sort: true },
        {
          filterId: FILTER_FILE_STATUS,
          filterTitle: "File availability",
          labeler: fileStatusLabeler,
        },
        { filterId: FILTER_DATA_TYPE, filterTitle: "Data type", sort: true },
      ].concat(...DUS_IN_FILE_FILTERS),
      fileColumns: FILE_BASELINE_COLUMNS,
      goldFilters: GOLD_FILTERS,
      requiredParams: ["org_name", "organism"],
      datasetFilters: DATATYPE_FILTERS,
    },
    search: {
      kingdom: "The JGI Data Portal",
      dataUrl: "/search/",
      suggestionUrl: "/search/?a=1&x=5",
      organismFilters: TAXONOMY_FILTERS,
      fileFilters: [
        { filterId: FILTER_FILE_TYPE, filterTitle: "File type", sort: true },
        {
          filterId: FILTER_FILE_STATUS,
          filterTitle: "File availability",
          labeler: fileStatusLabeler,
        },
        { filterId: FILTER_DATA_TYPE, filterTitle: "Data type" },
        { filterId: FILTER_DATA_GROUP, filterTitle: "Data group" },
      ].concat(...DUS_IN_FILE_FILTERS),
      fileColumns: deepClone(FILE_BASELINE_COLUMNS), // so can be spliced w/o affecting other defs
      goldFilters: GOLD_FILTERS,
      datasetFilters: DATATYPE_FILTERS,
    },
    other: {},
  };

  return config;
};

export default appConfig;
