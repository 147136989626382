/* istanbul ignore file */
import React, { useState, useEffect } from "react";
import { PropTypes } from "prop-types";
import FileAndPageContext from "../../context/FileAndPageContext";
import autil from "../../utils/ArrayUtils";
import { keycloakff } from "../../utils/LDFFUtils";
import {
  setLocalStorageSelectedFiles,
  clearLocalStorageSelectedFiles,
  setLocalStorageCartData,
  getLocalStorageCartData,
  clearLocalStorageCartData,
} from "../../utils/LocalStorageUtils";
import { fileCountInCart } from "../../utils/CartDataTools";
import { useSearchParams } from "../../hooks/useSearchParams";
import * as cartTool from "../../utils/CartDataTools";

export const PRIVATE_KEY = "include_private_data";

const FileAndPageProvider = ({
  children,
  handleKCLogin,
  handleLogin,
  ldClient,
}) => {
  const [selectedFiles, setSelectedFiles] = useState([]);
  const [cartData, _setCartData] = useState(null);

  const { include_private_data } = useSearchParams();

  const setCartData = (cdata, initialization = false) => {
    if (
      !cdata?.organisms ||
      !Array.isArray(cdata.organisms) ||
      cdata.organisms.length === 0
    ) {
      clearLocalStorageCartData();
      _setCartData(null);
      return { success: true };
    }

    if (!initialization && include_private_data) {
      cdata[PRIVATE_KEY] = 1;
    }
    if (!initialization && !include_private_data) {
      delete cdata[PRIVATE_KEY];
    }

    const info = setLocalStorageCartData(cdata); // cdata is a JSON

    if (info.success) {
      _setCartData(cdata);
    } else {
      info.file_count = cdata.organisms.reduce(
        (cnt, org) => cnt + org.files.length,
        0
      );
    }
    return info;
  };

  const clearCartData = () => {
    clearLocalStorageCartData();
    _setCartData(null);
  };

  useEffect(() => {
    const cData = getLocalStorageCartData();
    if (cData) {
      try {
        let data = JSON.parse(cData);

        if (data.pages) {
          // translate legacy cart data
          data = {
            organisms: data.pages[0].organisms.map((org) => ({
              id: org.id,
              files: org.files?.map((f) => f._id),
            })),
          };
        } else if (data.collection?.organisms.length > 0) {
          // translate collection cardData into the compact form
          data = {
            organisms: data.collection.organisms.map((org) => ({
              id: org.id,
              files: org.files?.map((f) => f._id),
            })),
          };
        } else if (
          data.organisms &&
          Array.isArray(data.organisms) &&
          data.organisms.length > 0 &&
          Array.isArray(data.organisms[0].files) &&
          data.organisms[0].files.length > 0 &&
          typeof data.organisms[0].files[0] === "object"
        ) {
          // translate compacted cardData into the lean form
          const tmp = { organisms: [] };
          data.organisms.forEach((org) => {
            if (Array.isArray(org.files)) {
              tmp.organisms.push({
                id: org.id,
                files: org.files.map((f) => f._id),
              });
            }
          });
          if (tmp.organisms.length > 0) {
            data = tmp;
          }
        }
        if (!Array.isArray(data.organisms) || fileCountInCart(data) === 0) {
          clearCartData();
        } else {
          setCartData(data, true);
        }
      } catch {
        clearCartData();
      }
    }
  }, []);
  const keycloak_ff = keycloakff(ldClient);

  const updateSelectedFiles = (selected) => {
    if (!autil.FileIdListsEqual(selected, selectedFiles)) {
      setSelectedFiles([...selected]);
    }
  };

  const saveFilesOnLogin = () => {
    if (selectedFiles?.length > 0) {
      setLocalStorageSelectedFiles(selectedFiles);
    } else {
      clearLocalStorageSelectedFiles();
    }

    if (keycloak_ff && handleLogin) {
      handleKCLogin();
    } else if (handleLogin) {
      handleLogin();
    }
  };

  // console.log("[FaP] cartData", JSON.stringify(cartData));
  const deleteFileFromCart = (file) => {
    // file : the raw file object
    const oid = file.organism_id;
    const orgIndex = cartTool.orgIndexOfOrgId(cartData, oid);

    if (orgIndex !== -1) {
      const orgs = cartData.organisms;
      const { files } = orgs[orgIndex];
      const fIndex = files.indexOf(file._id);

      if (fIndex !== -1) {
        if (files.length === 1) {
          // empty file list after delete -> delete organism
          orgs.splice(orgIndex, 1);
        } else {
          files.splice(fIndex, 1);
        }
      }
      setCartData({ ...cartData }); // new object to trigger state change
    }
  };

  const deleteOrgFromCart = (org) => {
    // org: the raw org object
    const orgIndex = cartTool.orgIndexOfOrgId(cartData, org.id);

    if (orgIndex !== -1) {
      cartData.organisms.splice(orgIndex, 1);
      setCartData({ ...cartData });
    }
  };
  const addFilesToCart = (files) => {
    // files: list of raw file objects
    // need to clone the existing cartData, preserve it's original data in case failed to save to localStorage
    const cData = cartData
      ? JSON.parse(JSON.stringify(cartData))
      : {
          organisms: [],
        };
    const { organisms } = cData;

    files.forEach((f) => {
      const oid = f.organism_id;
      const fid = f._id;
      if (!cartTool.fileInCart(cData, oid, fid)) {
        const orgCartIndex = organisms.map((org) => org.id).indexOf(oid);
        if (orgCartIndex === -1) {
          const fdata = { id: oid, files: [fid], top_hit_id: f.top_hit_id };
          const { mycocosm_portal_id } = f.metadata;
          if (mycocosm_portal_id) {
            fdata.mycocosm_portal_id = mycocosm_portal_id;
          }

          organisms.push(fdata);
        } else {
          organisms[orgCartIndex].files.push(fid);
        }
      }
    });

    return setCartData({ ...cData });
  };

  return (
    <FileAndPageContext.Provider
      value={{
        selectedFiles,
        updateSelectedFiles,
        handleLogin: saveFilesOnLogin,
        cartData,
        setCartData,
        clearCartData,
        deleteFileFromCart,
        deleteOrgFromCart,
        addFilesToCart,
      }}
    >
      {children}
    </FileAndPageContext.Provider>
  );
};

export default FileAndPageProvider;

FileAndPageProvider.defaultProps = {
  handleLogin: null,
  handleKCLogin: null,
  ldClient: null,
};

FileAndPageProvider.propTypes = {
  handleLogin: PropTypes.func,
  handleKCLogin: PropTypes.func,
  ldClient: PropTypes.shape(),
  children: PropTypes.element.isRequired,
};
